import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import LinkList from '../../components/LinkList/LinkList'
import LinkTile from '../../components/LinkTile/LinkTile'
import Alert from '../../components/Alert/Alert'

const RegistrarsPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      condoFirm: file(
        relativePath: {
          eq: "docs/HCRA Directives & Advisories/Condo Firm 2020 - Revised - Final.pdf"
        }
      ) {
        publicURL
      }

      condoTentative: file(
        relativePath: {
          eq: "docs/HCRA Directives & Advisories/Condo Tentative 2020 - Revised - Final.pdf"
        }
      ) {
        publicURL
      }

      freeholdFirm: file(
        relativePath: {
          eq: "docs/HCRA Directives & Advisories/Freehold Firm 2020 - Revised - Final.pdf"
        }
      ) {
        publicURL
      }

      freeholdTentative: file(
        relativePath: {
          eq: "docs/HCRA Directives & Advisories/Freehold Tentative - Revised - Final.pdf"
        }
      ) {
        publicURL
      }

      licenceDisplay: file(
        relativePath: {
          eq: "docs/HCRA Directives & Advisories/HCRA Directive - Licence Display Requirements v3 2021-01-30.pdf"
        }
      ) {
        publicURL
      }

      potlFirm: file(
        relativePath: {
          eq: "docs/HCRA Directives & Advisories/POTL Firm 2020 - Revised - Final.pdf"
        }
      ) {
        publicURL
      }

      potlTentative: file(
        relativePath: {
          eq: "docs/HCRA Directives & Advisories/POTL Tentative 2020 - Revised - Final.pdf"
        }
      ) {
        publicURL
      }
    }
  `)

  return (
    <Layout
      header={<FormattedMessage id='nav.directives' />}
      subheader={<FormattedMessage id='nav.licensingComp' />}
    >
      <SEO
        description={intl.formatMessage({
          id: 'metaTags.directivesDescription'
        })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.directives' })}
      />
      <Container>
        <Row>
          <Col md={12}>
            <h2>
              <FormattedMessage id='directivesAdvisories.headerA' />
            </h2>
            <p>
              <FormattedMessage id='directivesAdvisories.contentA' />
            </p>
            <LinkList
              links={[
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.addendumFile'
                  })}`,
                  text: <FormattedMessage id='directivesAdvisories.addendum' />
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.condoInfoSheetFile'
                  })}`,
                  text: (
                    <FormattedMessage id='directivesAdvisories.condoInfoSheet' />
                  )
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.floorAreaCalcFile'
                  })}`,
                  text: (
                    <FormattedMessage id='directivesAdvisories.floorAreaCalc' />
                  )
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.licenceDisplayFile'
                  })}`,
                  text: (
                    <FormattedMessage id='directivesAdvisories.licenceDisplay' />
                  )
                }
              ]}
            />
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col md={12}>
            <h2>
              <FormattedMessage id='directivesAdvisories.headerB' />
            </h2>
            <p>
              <FormattedMessage id='directivesAdvisories.contentB' />
            </p>
            <LinkList
              locale={intl.locale}
              links={[
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.1conductFile'
                  })}`,
                  text: <FormattedMessage id='directivesAdvisories.1conduct' />
                },

                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.2honestyFile'
                  })}`,
                  text: <FormattedMessage id='directivesAdvisories.2honesty' />
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.3defHomeFile'
                  })}`,
                  text: <FormattedMessage id='directivesAdvisories.3defHome' />
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.4defBuilderFile'
                  })}`,
                  text: (
                    <FormattedMessage id='directivesAdvisories.4defBuilder' />
                  )
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.5defVendorFile'
                  })}`,
                  text: (
                    <FormattedMessage id='directivesAdvisories.5defVendor' />
                  )
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.6defOwnerFile'
                  })}`,
                  text: <FormattedMessage id='directivesAdvisories.6defOwner' />
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.7interestPersonFile'
                  })}`,
                  text: (
                    <FormattedMessage id='directivesAdvisories.7interestPerson' />
                  )
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.8infoSecurityFile'
                  })}`,
                  text: (
                    <FormattedMessage id='directivesAdvisories.8infoSecurity' />
                  )
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.9competencyFile'
                  })}`,
                  text: (
                    <FormattedMessage id='directivesAdvisories.9competency' />
                  )
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.10dutyComplyFile'
                  })}`,
                  text: (
                    <FormattedMessage id='directivesAdvisories.10dutyComply' />
                  )
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.11pricingFile'
                  })}`,
                  text: <FormattedMessage id='directivesAdvisories.11pricing' />
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.12intimfile'
                  })}`,
                  text: <FormattedMessage id='directivesAdvisories.12intim' />
                },
                {
                  path: `../../../${intl.formatMessage({
                    id: 'directivesAdvisories.13licenseefile'
                  })}`,
                  text: (
                    <FormattedMessage id='directivesAdvisories.13licensee' />
                  )
                }
              ]}
            />
          </Col>
        </Row>

        {intl.locale === 'fr' && (
          <Row>
            <Col md={12}>
              <Alert header='Attention'>
                <p lang='fr'>
                  Pour les services en français, veuillez contacter le Service
                  des licences et à la clientèle de l’Office de réglementation
                  de la construction des logements au 416-487-HCRA (4272) ou{' '}
                  <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
                </p>
              </Alert>
            </Col>
          </Row>
        )}

        <Row className='pageRow'>
          <Col md={12}>
            <h2>Important Documents</h2>

            <p>
              Please include the appropriate document(s) with the Agreement of
              Purchase and Sale. See the{' '}
              <a
                href={`../../../${intl.formatMessage({
                  id: 'directivesAdvisories.addendumFile'
                })}`}
              >
                HCRA Directive - Addendum
              </a>{' '}
              and{' '}
              <a
                href={`../../../${intl.formatMessage({
                  id: 'directivesAdvisories.condoInfoSheetFile'
                })}`}
              >
                HCRA Directive - Condominium Information Sheet
              </a>{' '}
              for more information. Completed forms do not need to be sent to
              the HCRA.
            </p>
          </Col>

          <Col xl={3} md={4} sm={6}>
            <LinkTile path='../../../HCRA_CondoInfoSheet_2022_6_22.pdf'>
              <h3>Condominium Information Sheet</h3>
            </LinkTile>
          </Col>

          <Col xl={3} md={4} sm={6}>
            <LinkTile path={data.condoFirm.publicURL}>
              <h3>Condominium Form – Firm Occupancy Date</h3>
            </LinkTile>
          </Col>

          <Col xl={3} md={4} sm={6}>
            <LinkTile path={data.condoTentative.publicURL}>
              <h3>Condominium Form – Tentative Occupancy Date</h3>
            </LinkTile>
          </Col>

          <Col xl={3} md={4} sm={6}>
            <LinkTile path={data.freeholdFirm.publicURL}>
              <h3>Freehold Form – Firm Occupancy Date</h3>
            </LinkTile>
          </Col>

          <Col xl={3} md={4} sm={6}>
            <LinkTile path={data.freeholdTentative.publicURL}>
              <h3>Freehold Form – Tentative Occupancy Date</h3>
            </LinkTile>
          </Col>

          <Col xl={3} md={4} sm={6}>
            <LinkTile path={data.potlFirm.publicURL}>
              <h3>Limited Use Freehold Form – Firm Occupancy Date</h3>
            </LinkTile>
          </Col>

          <Col xl={3} md={4} sm={6}>
            <LinkTile path={data.potlTentative.publicURL}>
              <h3>Limited Use Freehold Form – Tentative Occupancy Date</h3>
            </LinkTile>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export default RegistrarsPage
